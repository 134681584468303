/* eslint-disable react/prop-types */
import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { FaPlus } from 'react-icons/fa';
import Layout from '../Components/Layout/Layout';
import Sponsor from '../Components/Sponsor/Sponsor';
import '../Components/Sponsor/sponsors.css';
import SEO from '../Components/seo';
// markup
const SponsorsPage = ({ data }) => (
  <>
    <SEO title="Sponsors" />
    <Layout page="Sponsors">
      <main id="main">
        <section id="sponsors" className="sponsors">
          <div className="container">
            <div className="row">
              {data.sponsors.info
                .map(({ frontmatter }) => (
                  <Sponsor
                    name={frontmatter.name}
                    image={frontmatter.image}
                  />
                ))}
              <div className="col-lg-4 mt-4">
                <div className="sponsor-item">
                  <center>
                    <Link to="/contact" className="btn">
                      <FaPlus />
                      {' '}
                      Sponsor Us
                    </Link>
                  </center>

                </div>
              </div>
            </div>

          </div>
        </section>
      </main>
    </Layout>
  </>

);
export const pageQuery = graphql` query SponsorsQuery {
  sponsors: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(sponsors)/.*\\.md$/"}}) {
    info: nodes {
      frontmatter {
        name
        image
      }
    }
  }
}
`;
export default SponsorsPage;
