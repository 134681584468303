import React from 'react';
import PropTypes from 'prop-types';

const Sponsor = ({ name, image }) => (
  <div className="col-lg-4 mt-4">
    <div className="sponsor-item">
      <img src={image} className="sponsor-img" alt={name} />
    </div>
  </div>
);
Sponsor.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Sponsor;
