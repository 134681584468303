import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './breadCrumbs.css';

const BreadCrumbs = ({ page }) => (
  <section className="breadcrumbs">
    <div className="container">

      <div className="d-flex justify-content-between align-items-center">
        <h2>{page}</h2>
        <ol>
          <li><Link to="/">Home</Link></li>
          <li>{page}</li>
        </ol>
      </div>

    </div>
  </section>
);
BreadCrumbs.propTypes = {
  page: PropTypes.string.isRequired,
};
export default BreadCrumbs;
